






















































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiSelffetchVerifierList,
    apiSelffetchVerifierStatus,
    apiSelffetchVerifierDel
} from '@/api/application/selffetch'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,
        ExportData
    }
})
export default class SelffetchVerifier extends Vue {
    /** S Data **/
    apiSelffetchVerifierList = apiSelffetchVerifierList
    // 表单数据
    form = {
        name: '', // 核销员名称
        status: '', // 核销员状态
        shop_name: '' // 门店名称
    }
    pager: RequestPaging = new RequestPaging()
    /** E Data **/

    /** S Methods **/
    // 搜索
    search() {
        this.pager.page = 1
        this.getSelffetchVerifierList()
    }

    // 重置搜索
    resetSearch() {
        Object.keys(this.form).map(key => {
            this.$set(this.form, key, '')
        })
        this.getSelffetchVerifierList()
    }

    // 获取列表数据
    getSelffetchVerifierList() {
        // 请求管理员列表
        this.pager
            .request({
                callback: apiSelffetchVerifierList,
                params: this.form
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }

    // 添加
    addSelffetchVerifier() {
        this.$router.push({
            path: '/selffetch/selffetch_verifier_edit',
            query: {
                mode: PageMode.ADD
            }
        })
    }

    // 删除
    onSelffetchVerifierDelete(data: any) {
        apiSelffetchVerifierDel({ id: data.id }).then(() => {
            // 删除成功就请求新列表
            this.getSelffetchVerifierList()
        })
    }

    // 编辑
    goSelffetchVerifierEdit(data: any) {
        this.$router.push({
            path: '/selffetch/selffetch_verifier_edit',
            query: {
                mode: PageMode.EDIT,
                id: data.id
            }
        })
    }

    // 更改状态
    changeSwitchStatus(value: 0 | 1, data: any) {
        apiSelffetchVerifierStatus({
            id: data.id,
            status: value
        }).catch(err => {
            this.getSelffetchVerifierList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getSelffetchVerifierList()
    }

    /** E Life Cycle **/
}
